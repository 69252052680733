app.loadSwiper = async( callback ) => {
	const { Swiper } = await import( 'swiper' );
	const modules = await import( 'swiper/modules' );
	await import( 'swiper/css/bundle' );

	callback( Swiper, modules );
};

app.loadRellax = async( callback ) => {
	const Rellax = await import( 'rellax' );

	callback( Rellax.default );
};

app.loadGsap = async( callback ) => {
	const { gsap } = await import( 'gsap' );
	const { ScrollTrigger } = await import( 'gsap/ScrollTrigger' );

	gsap.registerPlugin( ScrollTrigger );

	callback( gsap );
};

app.loadVivus = async( callback ) => {
	const Vivus = await import( 'vivus' );

	callback( Vivus.default );
};

app.loadPerfectScrollbar = async( callback ) => {
	const PerfectScrollbar = await import( 'perfect-scrollbar' );
	await import( 'perfect-scrollbar/css/perfect-scrollbar.css' );

	callback( PerfectScrollbar.default, {
		wheelSpeed: 1,
		wheelPropagation: true,
		minScrollbarLength: 20,
	} );
};
