/**
 * Handles the intersection observer callback.
 *
 * @param {Array} entries
 * @param {jQuery} $element
 * @returns {Void}
 */
const handleIntersection = ( entries, observer, delay ) => {
	setTimeout( () => {
		entries.forEach( ( entry ) => {
			if ( !entry.isIntersecting ) {
				return;
			}

			const { target } = entry;

			$( target ).addClass( 'is-animated' );
			observer.unobserve( target );
		} );
	}, delay );
};

/**
 * Creates and attaches an IntersectionObserver to an element.
 *
 * @param {Element} element
 * @returns {Void}
 */
const observeElement = ( element ) => {
	const $element = $( element );
	const delay = $element.data( 'animation-delay' ) || 0;

	const observer = new IntersectionObserver( ( entries ) => handleIntersection( entries, observer, delay ), {
		rootMargin: '0px 0px -5%',
	} );

	observer.observe( element );
};

/**
 * Initializes the animations.
 *
 * @returns {Void}
 */
export const initAnimations = () => {
	// Exclude Gutenberg Editor.
	if ( $( 'body' ).hasClass( 'block-editor-iframe__body' ) ) {
		return;
	}

	$( '[data-animation]' ).each( ( _, element ) => observeElement( element ) );
};

/**
 * Initialize animations.
 */
initAnimations();
